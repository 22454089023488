<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Form Pefindo'" />

    <div>
      <h4 class="pb-8">Form Input</h4>
      <form-scoring-pefindo :formScoring="formNewScoring" />
      <button-form
        :submit="submitDataPefindo"
        :loading="loading"
        class="pt-8"
      />
    </div>

    <v-divider class="my-8" v-if="this.showRecord" />

    <div v-if="this.showRecord">
      <h4 class="pb-8">Record</h4>
      <v-card
        v-for="(record, i) in records"
        :key="i"
        class="pa-8 mb-4"
        outlined
        elevation="0"
      >
        <div v-if="record.result.Status === 'SubjectFound'">
          <div v-for="(individual, j) in record.individualRecord" :key="j">
            <div>
              <span class="font-weight-bold">ID</span>
              : {{ individual.pefindoId }}
            </div>
            <div>
              <span class="font-weight-bold">Nama</span>
              : {{ individual.name }}
            </div>
            <div>
              <span class="font-weight-bold">Alamat</span>
              : {{ individual.address }}
            </div>
            <div>
              <span class="font-weight-bold">NIK</span>
              : {{ individual.ktp }}
            </div>
            <div>
              <span class="font-weight-bold">Tanggal Lahir</span>
              : {{ convertDate(individual.dateOfBirth) }}
            </div>
            <v-btn
              color="primary"
              class="text-none mt-8"
              @click="
                submitReportPefindo(individual.pefindoId, formNewScoring.option)
              "
              :loading="loadingTwo"
            >
              Check Report
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>

    <v-tabs
      v-if="this.showReport"
      v-model="tab"
      background-color="transparent"
      color="#D65646"
      grow
    >
      <v-tab class="text-none">
        Report
      </v-tab>
      <v-tab class="text-none">
        Contract
      </v-tab>
      <v-tab-item>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Date
                </th>
                <th class="text-left">
                  Grade
                </th>
                <th class="text-left">
                  Probability
                </th>
                <th class="text-left">
                  Score
                </th>
                <th class="text-left">
                  Trend
                </th>
                <th class="text-left">
                  Reason
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(report, i) in reports.result" :key="i">
                <td>{{ convertDate(report.date) }}</td>
                <td>{{ report.grade }}</td>
                <td>{{ report.probability }}</td>
                <td>{{ report.score }}</td>
                <td>{{ report.trend }}</td>
                <td>
                  <div
                    v-for="(reason, i) in report.reasons"
                    :key="i"
                    class="py-1"
                  >
                    {{ i + 1 }}. {{ reason.description }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>

      <v-tab-item>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Status
                </th>
                <th class="text-left">
                  Role of Client
                </th>
                <th class="text-left">
                  Sector
                </th>
                <th class="text-left">
                  Phase of Contract
                </th>
                <th class="text-left">
                  Type of Contract
                </th>
                <th class="text-left">
                  Amount
                </th>
                <th class="text-left">
                  Past Amount
                </th>
                <th class="text-left">
                  Total Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(contract, i) in reports.contract" :key="i">
                <td>{{ contract.status }}</td>
                <td>{{ contract.roleOfClient }}</td>
                <td>{{ contract.sector }}</td>
                <td>{{ contract.phaseOfContract }}</td>
                <td>{{ contract.typeOfContract }}</td>
                <td>
                  <div
                    v-for="(amount, i) in contract.amount"
                    :key="i"
                    class="py-1"
                  >
                    <div>Currency : {{ amount.currency }}</div>
                    <div>Value : {{ amount.value }}</div>
                  </div>
                </td>
                <td>
                  <div
                    v-for="(pastAmount, i) in contract.pastAmount"
                    :key="i"
                    class="py-1"
                  >
                    <div>Currency : {{ pastAmount.currency }}</div>
                    <div>Value : {{ pastAmount.value }}</div>
                  </div>
                </td>
                <td>
                  <div
                    v-for="(totalAmount, i) in contract.totalAmount"
                    :key="i"
                    class="py-1"
                  >
                    <div>Currency : {{ totalAmount.currency }}</div>
                    <div>Value : {{ totalAmount.value }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- <v-card
          v-for="(contract, i) in reports.contract"
          :key="i"
          class="pa-8 mb-4"
          outlined
          elevation="0"
        >
          <div>Status : {{ contract.status }}</div>
          <div>Role of Client : {{ contract.roleOfClient }}</div>
          <div>Sector : {{ contract.sector }}</div>
          <div>Phase of Contract : {{ contract.phaseOfContract }}</div>
          <div>Type of Contract : {{ contract.typeOfContract }}</div>
          <div>Amount :</div>
          <div v-for="(amount, i) in contract.amount" :key="i">
            <div>Currency : {{ amount.currency }}</div>
            <div>Value : {{ amount.value }}</div>
          </div>
          <div>Past Amount :</div>
          <div v-for="(pastAmount, i) in contract.pastAmount" :key="i">
            <div>Currency : {{ pastAmount.currency }}</div>
            <div>Value : {{ pastAmount.value }}</div>
          </div>
          <div>Total Amount :</div>
          <div v-for="(totalAmount, i) in contract.totalAmount" :key="i">
            <div>Currency : {{ totalAmount.currency }}</div>
            <div>Value : {{ totalAmount.value }}</div>
          </div>
        </v-card> -->
      </v-tab-item>
    </v-tabs>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperUser from "../../components/Stepper/StepperUser.vue";
import PopupRole from "../../components/Popup/PopupRole.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormScoringPefindo from "../../components/Form/FormScoringPefindo.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";
import converters from "../../helpers/converters.js";

export default {
  components: {
    HeaderContent,
    StepperUser,
    PopupRole,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormScoringPefindo,
  },

  data() {
    return {
      tab: null,
      showDialog: false,
      showSuccessDialog: false,
      showErrDialog: false,
      showRecord: false,
      showReport: false,
      loading: false,
      loadingTwo: false,
      formNewScoring: {
        option: "individual",
        name: "Bima Sakti",
        npwp: "",
        nik: "3150972902880002",
        bod: "1988-02-29",
      },
      records: [],
      reports: [],
    };
  },

  async mounted() {
    await store.dispatch("options/fetchLoanApplications");
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = false;
      this.showRecord = true;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    convertDate(date) {
      return converters.shortDate(date);
    },
    async submitDataPefindo() {
      this.loading = true;
      try {
        await store
          .dispatch("office/fetchDataPefindo", this.formNewScoring)
          .then((r) => {
            if (r) {
              this.records = r.data.result;
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
    async submitReportPefindo(id, type) {
      this.loadingTwo = true;
      const report = {
        id: id,
        subjectType: type[0].toUpperCase() + type.substring(1),
      };
      console.log(report, "re");
      try {
        await store.dispatch("office/fetchReportPefindo", report).then((r) => {
          if (r) {
            this.reports = r.data;
            this.showReport = true;
            this.loadingTwo = false;
          } else {
            this.showErrDialog = true;
            this.loadingTwo = false;
          }
        });
      } catch (err) {
        this.showErrDialog = true;
        this.loadingTwo = false;
      }
    },
  },
};
</script>
