<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-select
      v-model="formScoring.option"
      :items="optionOptions"
      item-text="name"
      item-value="value"
      :rules="[(v) => !!v || 'Mohon pilih jenis record']"
      label="Jenis Record"
      color="primary"
      outlined
      dense
      required
    ></v-select>

    <v-text-field
      v-model="formScoring.name"
      :rules="[(v) => !!v || 'Mohon isi nama']"
      label="Nama"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-if="formScoring.option === 'company'"
      v-model="formScoring.npwp"
      :rules="[(v) => !!v || 'Mohon isi npwp']"
      label="NPWP"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-if="formScoring.option === 'individual'"
      v-model="formScoring.nik"
      :rules="[(v) => !!v || 'Mohon isi NIK']"
      label="NIK"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-menu
      v-if="formScoring.option === 'individual'"
      ref="bodMenu"
      v-model="bodMenu"
      :close-on-content-click="false"
      :return-value.sync="formScoring.bod"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formScoring.bod"
          label="Tanggal Lahir"
          readonly
          v-bind="attrs"
          v-on="on"
          color="primary"
          outlined
          dense
          hide-details
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="formScoring.bod"
        color="primary"
        no-title
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="bodMenu = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.bodMenu.save(formScoring.bod)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </v-form>
</template>

<script>
export default {
  props: {
    formScoring: Object,
  },
  data: () => ({
    valid: true,
    bodMenu: false,
    optionOptions: [
      {
        name: "Individual",
        value: "individual",
      },
      {
        name: "Company",
        value: "company",
      },
    ],
  }),

  mounted() {
    this.formData = this.formScoring;
  },
};
</script>
